<template>
    <div>
        <PaginatedTableBase
            ref="tableRef"
            :headers="headers"
            :fetch-args="{ serviceId }"
            :fetch-function="fetchGuestbook"
        >
            <template #item.createDate="{ item }">
                <div class="text-caption text-center">{{ $moment.utc(item.createDate).local().format('LT') }}</div>
                <div class="text-center">{{ $moment.utc(item.createDate).local().format('l') }}</div>
            </template>

            <template #item.optIn="{ item }">
                <!-- <v-checkbox v-model="item.optIn" readonly></v-checkbox> -->
                <font-awesome-icon
                    v-if="item.optIn"
                    icon="fa-solid fa-circle-check"
                    style="color: #12b77f; font-size: 1.1rem"
                ></font-awesome-icon>

                <font-awesome-icon
                    v-else
                    icon="fa-solid fa-circle-xmark"
                    style="color: #ff5252; font-size: 1.1rem"
                ></font-awesome-icon>
            </template>

            <template #item.actions="{ item }">
                <div class="d-flex justify-center align-center">
                    <CustomTooltip :disable="!item.message" :tooltipProps="{ top: true }">
                        <template #activator>
                            <v-btn :disabled="!item.message" @click="showMessage(item)" small text>
                                <font-awesome-icon icon="fa-solid fa-message"></font-awesome-icon>
                            </v-btn>
                        </template>

                        <template #content>
                            <span>Show Message</span>
                        </template>
                    </CustomTooltip>

                    <CustomTooltip v-if="$auth.role.includes('SuperAdmin')" :tooltipProps="{ top: true }">
                        <template #activator>
                            <v-btn @click="showDetails(item)" small text>
                                <font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon>
                            </v-btn>
                        </template>

                        <template #content>
                            <span>Details</span>
                        </template>
                    </CustomTooltip>

                    <!-- <CustomTooltip v-if="$auth.role.includes('SuperAdmin')" :tooltipProps="{ top: true }">
                        <template #activator>
                            <v-btn @click="openDeleteModal(item)" small text>
                                <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
                            </v-btn>
                        </template>

                        <template #content>
                            <span>Show Message</span>
                        </template>
                    </CustomTooltip> -->
                </div>
            </template>
        </PaginatedTableBase>

        <v-dialog @click:outside="hideMessage" v-model="messageModal" max-width="600px">
            <v-card>
                <v-card-title> Message </v-card-title>

                <v-card-text>
                    <p v-if="selectedResponse" class="message-box">
                        {{ selectedResponse.message }}
                    </p>

                    <p v-else>No Message to display</p>
                </v-card-text>

                <v-card-actions>
                    <v-btn depressed @click="hideMessage">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog @click:outside="hideDetails" v-model="detailsModal" max-width="800px">
            <v-card>
                <v-card-title> Details </v-card-title>

                <v-card-text v-if="selectedResponse">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Id" :value="selectedResponse.id"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Service Id"
                                :value="selectedResponse.serviceId"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Funeral Home Id"
                                :value="selectedResponse.funeralHomeId"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Owner Id" :value="selectedResponse.ownerId"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="External Id"
                                :value="selectedResponse.externalId"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Email" :value="selectedResponse.email"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field label="Config URL" :value="selectedResponse.configURL"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field readonly label="Full Name" :value="selectedResponse.fulName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="First Name"
                                :value="selectedResponse.firstName"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Last Name" :value="selectedResponse.lastName"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Phone" :value="selectedResponse.phone"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Ip Address"
                                :value="selectedResponse.ipAddress"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field readonly label="Latitude" :value="selectedResponse.latitude"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field readonly label="Longitude" :value="selectedResponse.longitude"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field readonly label="City" :value="selectedResponse.city"></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Country Code"
                                :value="selectedResponse.countryCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Metro Code"
                                :value="selectedResponse.metroCode"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Region Code"
                                :value="selectedResponse.regionCode"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Region Name"
                                :value="selectedResponse.regionName"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Time Zone" :value="selectedResponse.timeZone"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Create Date"
                                :value="selectedResponse.createDate"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="Opt In" :value="selectedResponse.optIn"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="User Address 1"
                                :value="selectedResponse.userAddress1"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="User Address 2"
                                :value="selectedResponse.userAddress2"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="User City" :value="selectedResponse.userCity"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="User State"
                                :value="selectedResponse.userState"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field readonly label="User Zip" :value="selectedResponse.userZip"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="User Country"
                                :value="selectedResponse.userCountry"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                readonly
                                label="Relationship"
                                :value="selectedResponse.relationship"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Moderated Status"
                                :value="selectedResponse.moderatedStatus"
                            ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="6">
                            <v-text-field label="Custom Fields" :value="selectedResponse.customFields"></v-text-field>
                        </v-col> -->
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn depressed @click="hideDetails">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog @click:outside="closeDeleteModal" v-model="deleteModal" max-width="600px">
            <v-card>
                <v-card-title> Delete Response </v-card-title>

                <v-card-text v-if="selectedResponse">
                    <div>Email: {{ selectedResponse.email }}</div>
                    <div>Opt In: {{ selectedResponse.optIn }}</div>
                    <div>
                        Date:
                        <span>
                            {{ $moment.utc(selectedResponse.createDate).local().format('l') }} -
                            {{ $moment.utc(selectedResponse.createDate).local().format('LT') }}
                        </span>
                    </div>
                </v-card-text>

                <v-card-text> This cannot be undone. Please confirm to continue.</v-card-text>

                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="closeDeleteModal">Close</v-btn>
                    <v-btn depressed color="error" @click="deleteResponse(selectedResponse)">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PaginatedTableBase from './PaginatedTableBase.vue';
import GuestbookService from '@/services/guestbook.service';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';

export default {
    components: { PaginatedTableBase, CustomTooltip },
    props: {
        serviceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            messageModal: false,
            detailsModal: false,
            deleteModal: false,
            selectedResponse: null,
            headers: [
                { text: 'Date', value: 'createDate', align: 'center' },
                { text: 'Email', value: 'email' },
                { text: 'Full Name', value: 'fullName' },
                { text: 'Opt In', value: 'optIn' },
                { text: 'Phone', value: 'phone' },
                { text: 'Relationship', value: 'relationship' },
                // { text: 'City', value: 'city' },
                // { text: 'Country Code', value: 'countryCode' },
                // { text: 'Custom Fields', value: 'customFields' },
                // { text: 'External ID', value: 'externalId' },
                // { text: 'First Name', value: 'firstName' },
                // { text: 'Funeral Home ID', value: 'funeralHomeId' },
                // { text: 'ID', value: 'id' },
                // { text: 'IP Address', value: 'ipAddress' },
                // { text: 'Last Name', value: 'lastName' },
                // { text: 'Latitude', value: 'latitude' },
                // { text: 'Longitude', value: 'longitude' },
                // { text: 'Metro Code', value: 'metroCode' },
                // { text: 'Owner ID', value: 'ownerId' },
                // { text: 'Region Code', value: 'regionCode' },
                // { text: 'Region Name', value: 'regionName' },
                // { text: 'Service ID', value: 'serviceId' },
                // { text: 'Time Zone', value: 'timeZone' },
                // { text: 'User Address 1', value: 'userAddress1' },
                // { text: 'User Address 2', value: 'userAddress2' },
                // { text: 'User City', value: 'userCity' },
                // { text: 'User Country', value: 'userCountry' },
                // { text: 'User State', value: 'userState' },
                // { text: 'User Zip', value: 'userZip' },
                // { text: 'Zip Code', value: 'zipCode' },
                // { text: 'Message', value: 'message' },
                { text: 'Actions', value: 'actions' },
            ],
        };
    },
    methods: {
        async fetchGuestbook(params) {
            const token = (await this.$auth.getIdTokenClaims()).__raw;
            const api = GuestbookService(token);
            const response = await api.getResponsesByService(params.serviceId, params);
            return response.data;
        },
        showMessage(response) {
            this.selectedResponse = response;
            this.messageModal = true;
        },
        hideMessage() {
            this.messageModal = false;
            this.selectedResponse = null;
        },
        showDetails(response) {
            if (!this.$auth.role.includes('SuperAdmin')) {
                return;
            }

            this.selectedResponse = response;
            this.detailsModal = true;
        },
        hideDetails() {
            this.detailsModal = false;
            this.selectedResponse = null;
        },
        async deleteResponse(response) {
            console.log(response, 'response to delete');

            const token = (await this.$auth.getIdTokenClaims()).__raw;
            const api = GuestbookService(token);
            const delResponse = await api.deleteResponse(response.id);
            console.log(delResponse, delResponse);
            this.closeDeleteModal();

            this.$refs.tableRef.fetchData();
        },
        openDeleteModal(response) {
            this.selectedResponse = response;
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
            this.selectedResponse = null;
        },
    },
};
</script>

<style scoped lang="scss">
.message-box {
    background-color: $light-gray;
    padding: 12px;
    border: 2px solid $medium-gray;
    border-radius: 4px;
}
</style>
